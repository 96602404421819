<template>
  <div class="make-box">
    <HomeMake v-if="moduleId === 12" />
    <DetailPagesMake v-if="moduleId === 13" />
    <CustomPageMake v-if="moduleId === 14" />
  </div>
</template>

<script>
import HomeMake from '@/components/student/exam/storeDecoration/HomeMake.vue'
import DetailPagesMake from '@/components/student/exam/storeDecoration/DetailPagesMake.vue'
import CustomPageMake from '@/components/student/exam/storeDecoration/CustomPageMake.vue'
export default {
  name: "HomeDetailCustomMake",
  components:{
    HomeMake,
    DetailPagesMake,
    CustomPageMake
  },
  data(){
    return {
      moduleId:Number(this.$route.query.moduleId) || null,
    }
  }
}
</script>

<style scoped lang="scss">
.make-box{
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
}

</style>
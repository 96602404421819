<template>
  <div class="home-box">
    <el-table class="customTable" :data="taskList" style="width: 100%; margin-top: 16px; flex: 1;" height="1%" size="medium"
              :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
              :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
      <el-table-column prop="taskName" align="left" label="任务详情"></el-table-column>
      <el-table-column prop="downloadText" align="center" label="素材下载">
        <template slot-scope="scope">
          <el-link :underline="false" :href="fileDownload" type="success">{{scope.row.downloadText}}</el-link>
        </template>
      </el-table-column>
      <el-table-column align="center" label="首页制作（支持拓展名：jpg、jpeg和png，文件大小不能超过3M）">
        <template slot-scope="scope">
          <el-button class="select-file" type="primary" onclick="fileInput.click()" plain>{{fileName ? '重新上传' :  '选择文件' }}</el-button>
          <input
              ref="modelInput"
              type="file"
              id="fileInput"
              @change="uploadFile($event)"
              style="display:none"
              accept=".jpg,.jpeg,.png"
          />
          <span class="no-select-file">{{fileName ? fileName : '未选择任何文件' }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="footer-btn" style="text-align: center;padding: 20px 0">
      <el-button type="primary" @click="submitTask">提交</el-button>
    </div>
    <successDialog :successDialogVisible="successDialogVisible" @closeDialog="handleClose" />
  </div>
</template>

<script>
import successDialog from '@/components/successDialog.vue'
import {decorationIndex, decorationUpload, decorationDetail} from "@/utils/apis";
export default {
  name: "DetailPagesMake",
  components:{
    successDialog
  },
  data(){
    return {
      taskList:[
        {
          taskName:'详情页制作',
          downloadText:'点击下载',
        }
      ],
      fileDownload:localStorage.getItem('file_download') || null,
      opId:this.$route.query.id || null,
      courseId:this.$route.query.course_id || null,
      fileName:null,
      filePath:null,
      amendId:null, // 学生修改id
      successDialogVisible:false,
    }
  },
  mounted() {
    this.getDecorationIndex()
  },
  methods:{
    // 获取上次的答题内容
    getDecorationIndex(){
      let params = {
        type:2
      }
      if(this.opId){
        params.course_id = this.courseId;
        params.op_id = this.opId;
      }
      decorationIndex(params).then((res)=>{
        if(res.data.id){
          this.amendId = res.data.id;
          this.filePath = res.data.file_path;
          this.fileName = res.data.file_name
        }
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    // 上传文件
    uploadFile(event){
      let file = event.target.files[0];
      let formData = new FormData();
      formData.append('type', 2);
      formData.append('img', file);
      decorationUpload(formData).then((res)=>{
        this.fileName = file.name;
        this.filePath = res.data.src;
        this.$refs.modelInput.value = null;
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    // 提交
    submitTask(){
      if(this.filePath){
        let formData = new FormData();
        formData.append('file_path', this.filePath);
        formData.append('file_name', this.fileName);
        if(this.opId){
          formData.append('course_id', this.courseId);
          formData.append('op_id', this.opId);
        }
        if(this.amendId){
          formData.append('id', this.amendId)
        }
        decorationDetail(formData).then((res)=>{
          this.successDialogVisible = true;
        }).catch((err)=>{
          console.log('err',err)
        })
      } else {
        this.$message.warning('请选择文件')
      }
    },
    handleClose(){
      this.successDialogVisible = false;
    }
  }
}
</script>

<style scoped lang="scss">
.home-box{
  height: 100%;
  display: flex;
  flex-direction: column;
}
.select-file{
  margin-right: 10px;
  background-color: #fff;
  &:hover{
    background-color: #1CB064;
    color: #fff;
  }
}
.no-select-file{
  font-size: 14px;
  color: #666;
}
</style>